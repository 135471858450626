import React, { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../components/DropdownSelectBox/DropdownSelectBox.component";
import SentComponent from "../../components/Sent/Sent.component";
import {assignAssetToEmployee} from "../../services/catalog.service";

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    width: 100px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    users?: any[];
    assetId: string;
    fetchAssets?: () => void;
}

const AssignAssetFeature:React.FC<InputTextProps> = ({ title, users, assetId, fetchAssets })=> {
    const [notSent, setnotSent] = useState(true);

    const handleSubmit = async ()=> {
        try{
            await assignAssetToEmployee({
                userId: assignedTo,
                assetsId: assetId,
            });
            fetchAssets && fetchAssets();
            setnotSent(false);
        }
        catch(err){
            setnotSent(true);
            console.log(err);
        }
    }
    const options = users.map((item) => {
        return {value: item.id, label:  `${item.firstName} ${item.lastName}` };
    });
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [assignedTo, setAssignedTo] = useState(options[0].value);
    const handleOptionChange = (option: any) => {
        setAssignedTo(option.value);
        setSelectedOption(option);
    };

    return <>
        {notSent ? <div>
            <FormItem>
                <DropdownSelectBox label="Assign to" options={options} selectedOption={selectedOption} onChange={handleOptionChange} />
            </FormItem>

            <FormItem>
                <Button  onClick = { () => handleSubmit() }>Assign</Button>
            </FormItem> </div> : <SentComponent title = {title} /> }
    </>
};

export default AssignAssetFeature;
