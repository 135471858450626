import styled from "styled-components"

const InputForm = styled.div`
    margin-top: 20px;
`
const InputLabel = styled.div`
    color: #515251;
    font-size: 12px;
    margin-bottom: 5px;
`;

const InputWrapper = styled.div``;

const InputText = styled.input<InputTextProps>`
    width: ${props => props.fullWidth ? '100%' : '400px'};
    height: 30px;
    font-size: 14px;
    border: 1px solid #ebebeb;
    padding-left: 10px;
    border-radius: 5px;
    &:focus {
        outline: none;
    }
`;

interface InputTextProps{
    label?: string;
    type: string;
    placeholder?: string;
    fullWidth?: boolean;
    onChildStateChange?: any;
    value?: string; 
 }

const InputTextComponent:React.FC<InputTextProps> = ({ label, type, placeholder, fullWidth, onChildStateChange, value })=> {
    
  return (<>
    <InputForm>
        {label ? <InputLabel>{label}</InputLabel> : null }
            <InputWrapper>
                <InputText label={label} fullWidth={fullWidth} onChange={onChildStateChange} type={type} placeholder={placeholder} value={value} />
            </InputWrapper>
    </InputForm>
  </>);
}

export default InputTextComponent;
