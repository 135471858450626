import {  useState } from 'react';
import styled from 'styled-components';
import DropdownSelectBox from '../../components/DropdownSelectBox/DropdownSelectBox.component';
import InputTextComponent from '../../components/input/InputText.component';
import { CreateOrganization } from '../../services/account.service';
import './styles/account.css';
import { industriesList } from '@rocket-desert/common-lib';

const ErrorWrapper = styled.div`
  width: auto;
  height: 20px;
  margin-top: 20px;
  padding: 5px;
  background: #ff8f9a;
  border: 1px solid #a8323e;
`;

const InfoWrapper = styled.div`
padding: 20px;
background-color: #04AA6D;
color: white;
margin-bottom: 15px;
margin-top: 25px;
width: 90%;
`;

function CreateAccountPage() {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const industryList = industriesList.length ? industriesList.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Industry"}];
  const [selectedIndustryOption, setSelectedIndustryOption] = useState(industryList[0]);
  const [industryValue, setIndustryValue] = useState(industryList[0]);
  const handleSubmitfunc = async () => {
    try {
        await CreateOrganization({email, password, name, industry: industryValue});
        setError(false);
        setSuccess(true);      
 
  } catch(e) {
    console.log(email, password);
    console.log('ok', e);
    setError(true);
  }
  }

  const handleChildStateChangeName = (event: any) => {
    setName(event.target.value);
  };

  const handleChildStateChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleChildStateChangePassword  = (event: any) => {
    setPassword(event.target.value);
  };

  const handleIndustryOptionChange = (option: any) => {
    setSelectedIndustryOption(option);
    setIndustryValue(option.value);
};
  
  return (
    <div className="wrapper">
      <div className="leftSideWrapper">
          <div className="sideWrapper">
          <div className="logo">
              Rocket Desert
          </div>
          <div className="description">
            Maximizing Business Potential through Superior Onboarding Experiences
          </div>
          </div>
      </div>
      <div className="rightSide">
        <div className="loginWrapper">
              <div className="Texthead">
                <h3>Create Rocket Desert Account</h3>                
              </div>
              <div className="responsive-head">
                <h3>Create Rocket Desert Account</h3>                
              </div>
              {success ? <InfoWrapper>Your company is registered on our platform. We will contact you, to verify company in 1-2 days and will activate your account after it!</InfoWrapper> : null}
              <div className={"form " + (success ? "hide" : "")}>

                  <InputTextComponent  onChildStateChange={handleChildStateChangeName}  label="Organization name" type="text" />

                  <InputTextComponent  onChildStateChange={handleChildStateChange}  label="Organization Email" type="text" />

                  <DropdownSelectBox label="Choose Industry" options={industryList} selectedOption={selectedIndustryOption} onChange={handleIndustryOptionChange} />

                  <InputTextComponent onChildStateChange={handleChildStateChangePassword}  label="Password" type="password" />                                                
                  
                  <div className="loginButton">
                      <div className="loginBtn" onClick={handleSubmitfunc}>Create an account</div>
                      <h4 className="terms">By confirming your email, you agree to our <a href="https://dev.rocketdesert.com/terms-of-service" target="_blank" rel="noopener noreferrer" className="anchor color-primary">Terms of Service</a> and that you have read and understood our <a href="https://dev.rocketdesert.com/privacy" target="_blank" rel="noopener noreferrer" className="anchor color-primary">Privacy Policy</a>.</h4>
                  </div>          
              </div>
              <div className="errorMessage">
                    {error ? <ErrorWrapper>Error while registration, please try later!</ErrorWrapper> : null}
                  </div>
              <div className="getAccess">
                      <p>already have an account? <a href="/">Sign In</a></p>
                  </div>
          </div> 
      </div>
      <div className="copyright">&copy; 2023 - 2024 Rocket Desert, Inc. All rights reserved
        <a className="legalinks" href="https://rocketdesert.com/terms">Terms</a> and 
        <a className="legalinks" href="https://rocketdesert.com/privacy">Privacy Policy</a>
    </div>    

    </div>
  );
}

export default CreateAccountPage;
