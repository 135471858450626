import * as React from 'react';
import { useParams } from 'react-router-dom';
import ModalComponent from '../../components/modal/modal.component';
import AddEmployeeFeature from '../../features/boarding/onboarding/flow/add-employee.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getChapter } from '../../services/chapter.service';
import { getPositions } from '../../services/positions.service';
import { getTeams } from '../../services/team.service';
import { getWorkflows } from '../../services/workflows.service';
import { convertDate } from '../../utils/date';
import './styles/chapters.style.css';
interface Props {
    children?: React.ReactNode
}
const ChaptersDetailsPage: React.FunctionComponent<Props> = (props:Props) => {
    const [workflows, setWorkflows] = React.useState<any[]>([]);
    const [positions, setPositions] = React.useState<any[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);
    const [currentChapeter, setCurrentChapter] = React.useState<any>();

    let { chapterId } = useParams();
    React.useEffect(() => {
        async function getChapterDetails() {
            const chapter = await getChapter(chapterId);
            console.log("INFO", chapter);
            setCurrentChapter(chapter);
        }
        getChapterDetails();
    }, [chapterId]);


    React.useEffect(() => {
        async function getTeamsList() {
            const teams = await getTeams();
            console.log(teams);
            setTeams(teams);
        }
        getTeamsList();
    }, []);

    React.useEffect(() => {
        async function getPositionsList() {
            const positions = await getPositions();
            console.log(positions);
            setPositions(positions);
        }
        getPositionsList();
    }, []);


    React.useEffect(() => {
        async function getWorkflowsList() {
            const workflows = await getWorkflows();
            console.log(workflows);
            setWorkflows(workflows.data);
        }
        getWorkflowsList();
    }, []);

    const {isShowing, toggle} = useModal();
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Chapters Page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> { currentChapeter ? currentChapeter.name : null } </div>
            </div>

            <div className="controls-page">
                <div>
                    <h1>Chapter Details</h1>
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="Invite members"
                >
                    <AddEmployeeFeature teams={teams} positions={positions} workflows={workflows} />
                 </ModalComponent>

            </div>
            {currentChapeter ?
            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>
                    <th className="team-id">Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Lead</th>
                    <th>Created at</th>
                    </tr>
                </thead>
                <tbody>
                <tr className="team-tr">
                    <td className="team-id-value">1</td>
                    <td>{currentChapeter.name}</td>
                    <td>{currentChapeter.status}</td>
                    <td>{currentChapeter.chapterLead.firstName} {currentChapeter.chapterLead.lastName}</td>
                    <td>{ convertDate(currentChapeter.createdAt)}</td>
                </tr>
                </tbody>
            </table> : null }
        </DashboardLayout>
    </>
}

export default ChaptersDetailsPage;
