import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CreateButtonComponent from '../../components/Buttons/CreateButton.component';
import ModalComponent from '../../components/modal/modal.component';
import NewTeamFeature from '../../features/teams/create-team.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getTeams } from '../../services/team.service';
import { getTeamLeaders } from '../../services/user.service';
import { convertDate } from '../../utils/date';
import './styles/teams.style.css';
interface Props {
    children?: React.ReactNode
}
const TeamsPage: React.FunctionComponent<Props> = (props:Props) => {
    const [leaders, setLeaders] = React.useState<{id: number | string; firstName: string}[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);
    console.log(props);
    React.useEffect(()=> {
        async function getLeaders() {
            const users = await getTeamLeaders();
            setLeaders(users.users);
            console.log(users);
        }
        getLeaders();
    },[]);

    const fetchTeams = async () => {
        const teams = await getTeams();
        setTeams(teams);
    }


    React.useEffect(()=> {
        async function getTeamsList() {
            const teams = await getTeams();
            setTeams(teams);
        }
        getTeamsList();
    },[]);     

    const {isShowing, toggle} = useModal();
    const navigate = useNavigate();
    const handleNavigate = async (url: string) => {
        navigate(url);
    }
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Teams Page </div>
            </div>

            <div className="controls-page">
                <div>
                    <h1>All Teams</h1>
                </div>
                <div className="controls-list-page">
                    <CreateButtonComponent textColor="#ffffff" color="#2a7aeb"  text="+ New Team" onClick={toggle} />
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="New Team"
                >
                    <NewTeamFeature fetchTeams={fetchTeams} users={leaders} title = 'Create Team' />
                </ModalComponent>

            </div>

            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>
                    <th className="team-id">Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Lead</th>                    
                    <th>Created at</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                { teams ? teams.map((item, index)=> {
                    return <tr className="team-tr" key={item.id} onClick = { () => handleNavigate(`${item.id}/view`) }>
                    <td className="team-id-value">{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>{item.teamLeader.firstName} {item.teamLeader.lastName}</td>                   
                    <td>{ convertDate(item.createdAt) }</td>
                    <td><span onClick={(e) => { e.stopPropagation(); handleNavigate(`/team/edit/${item.id}`);}}>Edit</span></td>
                </tr>
                }) : null }
                </tbody>
            </table>
        </DashboardLayout>
    </>
}

export default TeamsPage;