import { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../../../components/input/InputText.component";
import SentComponent from "../../../../components/Sent/Sent.component";
import { createOnboarding } from "../../../../services/onboardings.service";
const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    min-width: 100px;
    max-width: 200px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    workflows?: any;
    positions?: any;
    teams?: any;
    fetchOnboardings?: () => void;
 }

const CreateOnboardingFeature:React.FC<InputTextProps> = ({ title, workflows, positions, teams, fetchOnboardings })=> {
    const [notSent, setnotSent] = useState(true);

    const handleSubmit = async ()=> {
        setnotSent(false);
        await createOnboarding({
            boardingType: "ONBOARDING",
            workflowId: workflowValue,
            fullname: `${firstName} ${lastName}`,
            email: email,
            teamId: teamValue,
            positionsId: positionValue,
            startDate: startDate,
         });
        fetchOnboardings && fetchOnboardings();
    }

    // const options = UsersList.map((item)=> item.name);
    // const handleOptionChange = (option: string) => {
    //     setSelectedOption(option);
    // };

    // const positions = PositionsList.map((item) =>  { return {value: item.value, label: item.label}});
    // positions[0].label = "Default Position";
    // const [selectedOption, setSelectedOption] = useState(options[0]);
    // const [selectedPositionsOption, setSelectedPositionOption] = useState(positions[0]);

    const [firstName, setFirstname] = useState("");
    const [lastName, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [startDate, setStartDate] = useState("");

    const workflowsList = workflows.map((item) =>  { return {value: item.id, label: item.name}});
    const [selectedWorkflowsOption, setSelectedWorkflowsOption] = useState(workflowsList[0]);

    const teamList = teams.map((item) =>  { return {value: item.id, label: item.name}});
    const [selectedTeamsOption, setSelectedTeamsOption] = useState(teamList[0]);

    const positionsList = positions.length ? positions.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Position"}];
    const [selectedPositionsOption, setSelectedPositionOption] = useState(positionsList[0]);
    console.log("DATA", positionsList);
    const handleFirstNameChange = (event: any) => {
        setFirstname(event.target.value);
    };

    const handleLastNameChange = (event: any) => {
        setLastname(event.target.value);
    };

    const handleDate = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePositionOptionChange = (option: any) => {
        setPosition(option.value);
        setSelectedPositionOption(option);
    };

    const handleWorkflowsOptionChange = (option: any) => {
        console.log("select", option);
        setSelectedWorkflowsOption(option);
        setWorkflowValue(option.value);
    };



    // const teams = TeamsList.map((item) => {return {value: item.id, label: item.name }});
    // const [selectedTeamOption, setSelectedTeamOption] = useState(teams[0]);

    const handleTeamsOptionChange = (option: any) => {
        setTeamValue(option.value);
        setSelectedTeamsOption(option);
    };


    const [positionValue, setPosition] = useState(positionsList[0].value);
    const [teamValue, setTeamValue] = useState(teamList[0].value);
    const [workflowValue, setWorkflowValue] = useState(workflowsList[0].value);


  return <>
    {title}
    {notSent ? <div>
    <FormItem>
        <InputTextComponent label="First name" onChildStateChange = {handleFirstNameChange } fullWidth={true} type="text"  placeholder="First name..." />
    </FormItem>
        <FormItem>
            <InputTextComponent label="Last name" onChildStateChange = {handleLastNameChange } fullWidth={true} type="text"  placeholder="Last name..." />
        </FormItem>
    <FormItem>
        <InputTextComponent label="Invitation email" onChildStateChange = {handleEmailChange} fullWidth={true} type="text"  placeholder="User email..." />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Choose Workflows" options={workflowsList} selectedOption={selectedWorkflowsOption} onChange={handleWorkflowsOptionChange} />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Choose position" options={positionsList} selectedOption={selectedPositionsOption} onChange={handlePositionOptionChange} />
    </FormItem>
    <FormItem>
    <DropdownSelectBox  label="Choose Team" options={teamList} selectedOption={selectedTeamsOption} onChange={handleTeamsOptionChange} />
    </FormItem>
    <FormItem>
        <InputTextComponent label="Start Date" onChildStateChange = {handleDate} fullWidth={true} type="date"  placeholder="Choose Date" />
    </FormItem>
    <FormItem>
        <Button onClick = { () => handleSubmit() } >Send Invitation</Button>
    </FormItem></div> : <SentComponent title="Invitation has been sent!" /> }
  </>
};

export default CreateOnboardingFeature;
