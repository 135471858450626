import axios from "axios";
import authHeader from "./authHeader.service";
export const API_URL = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000/';

export const register = (username: string, email: string, password: string) => {
  return axios.post(`${API_URL}/auth`, {
    username,
    email,
    password,
  });
};

export const checkAuth = async () => {
  return await axios.get(`${API_URL}auth/me`, { headers: authHeader() })
    .then((response)=> {
      return response;
    });
}

export const login = (username: string, password: string) => {
  console.log(API_URL);
  return axios
    .post(`${API_URL}auth/login`, {
      email: username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=> console.error(e)); 
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const resetPassword = async (email: string) => { 
    return await axios
    .post(`${API_URL}auth/password/reset`, {
      email: email
    })
      .then((response)=> {
        return response.data;
      });
};

export const checkResetPasswordToken = async (resetToken: string) => { 
  return await axios
  .post(`${API_URL}auth/token/check`, {
    token: resetToken
  })
    .then((response)=> {
      return response.data;
    });
};

export const setPassword = async (resetToken: string, password: string) => { 
  return await axios
  .put(`${API_URL}auth/password`, {
    resetToken: resetToken,
    password: password
  })
    .then((response)=> {
      return response.data;
    });
};
