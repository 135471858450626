import './styles/boarding-tab.style.css';
import { TabsEnum } from '../onboarding/onboarding-table.feature';
import EmployeeFeature from '../onboarding/employee.feature';

interface Props {
    title?: string;
}

const EmployeeTabFeature: React.FunctionComponent<Props> = ({ title }) => {
    console.log(title)
    return <>
      <div className="content">
        <EmployeeFeature tabType = { TabsEnum.EMPLOYED } title="Add Employee"  showSearch={true} showControls={true} />
      </div>
    </>;
};


export default EmployeeTabFeature;
