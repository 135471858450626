import * as React from 'react';
import styled from "styled-components";
import { convertDate } from '../../utils/date';
import {ActionType, getReadableActionType} from "../../utils/action-types.utils";

const Iteam = styled.tr`
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
`;

const IdIteam = styled.td`
    text-align: center;
`;
interface Props {
    id: string | number;
    name: string;
    status: string;
    owners: string;
    revertible: string;
    actionType: string;
    createdAt: string;
    onEdit?: React.MouseEventHandler;
    onDelete?: React.MouseEventHandler;
}
const ActionsTableIteam: React.FunctionComponent<Props> = ({ id, name, actionType, revertible, status, owners, createdAt, onEdit,  onDelete}) => {

    return <Iteam>
        <IdIteam>{id}</IdIteam>
        <td> {name} </td>
        <td> {status} </td>
        <td> {owners} </td>
        <td> {revertible} </td>
        <td> {getReadableActionType(actionType as ActionType)} </td>
        <td>{convertDate(createdAt)}</td>
        <td><span onClick={onEdit}>Edit</span>&nbsp;<span onClick={onDelete}>Delete</span></td>
    </Iteam>
}

export default ActionsTableIteam;