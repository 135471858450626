import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getWorkflows } from '../../services/workflows.service';
import WorkflowTableIteam from './workflow-table-items';
import OnboardingTable, { TabsEnum } from './workflow-table.feature';
import { deleteWorkflow } from '../../services/workflows.service';

interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType?: TabsEnum;
}

const WorkflowsFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls, tabType }) => {

    const [workflows, setWorkflows] = React.useState<any[]>([]);
    const navigate = useNavigate();

    const workflowsFetch = async () => {
        const workflows = await getWorkflows();
        setWorkflows(workflows.data);
    };

    React.useEffect(() => {
        async function getWorkflowsList() {
            const workflows = await getWorkflows();
            console.log(workflows);
            setWorkflows(workflows.data);
        }
        getWorkflowsList();
    }, []);

    const handleEdit = async (id: string) => {
        navigate('/workflow/edit/' + id);       
    };
    const handleDelete = async (id: string) => {
       await deleteWorkflow(id);       
     };

    return <>
        <OnboardingTable
            title = { title } 
            showControls = { showControls } 
            showTitle = { showTitle } 
            showSearch = { showSearch }
            tabType = { tabType }
            worfklowFetch={workflowsFetch}
        >
            { workflows ? workflows.map((item, i )=> {
                return  <WorkflowTableIteam 
                id={i + 1} 
                key={item.id}
                name={item.name}
                status={item.status}
                actions={item.actions}                
                createdAt={item.createdAt}
                onEdit={() => handleEdit(item.id)} 
                onDelete={() => handleDelete(item.id)}
            />
            }) : null }
           
     </OnboardingTable>
    </>
}

export default WorkflowsFeature;