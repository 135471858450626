import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getMetrics = async () => {
    try {
        return await axios.get(API_URL + "metrics", { headers: authHeader() }).then(item=> item.data);
    } catch (error) {
        console.log("Error retrieving metrics:", error);
        throw error;
      }
};