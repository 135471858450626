import styled from "styled-components"

const Button = styled.button<{bgColor?: string, txtColor?: string}>`
    background: ;
    border: 1px solid #286efa;
    color: #fff;
    border-radius: 0.25rem;
    font-size: .875rem;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
    white-space: nowrap;
    line-height: 1.125rem;
    cursor:pointer;
    background-color: ${props => props.bgColor ? props.bgColor : '#286efa'};
    color: ${props => props.txtColor ? props.txtColor : '#ffffff'};
`;

interface Props{
    text: string;
    color?: string;
    textColor?: string;
    onClick?: React.MouseEventHandler;
 }

const ButtonComponent:React.FC<Props> = ({ text, color, textColor, onClick })=> (
  <>
    <Button onClick={onClick} bgColor={color} txtColor={textColor}>
       { text }
    </Button>
  </>
)

export default ButtonComponent;