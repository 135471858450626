import * as React from 'react';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from '../../layouts/DashboardLayout';
import DropdownSelectBox from "../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../components/input/InputText.component";
import { getTeamLeaders } from "../../services/user.service";
import { getChapter, updateChapter } from '../../services/chapter.service';
import LinkButton from '../../components/LinkButton/LinkButton.component';
import Button from '../../components/Buttons/Button.component';

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Form = styled.div`
    width: 50%;
`;

const FormButtonsItem = styled.div`
    display: flex;
    gap:10px;
    flex-wrap: nowrap;
    margin-top: 15px;
`;

function ChapterEditPage() {
          
    const  statuses = [{value: "ACTIVE", label: "Active"}, {value: "NOT_ACTIVE", label: "Inactive"}];
    const [name, setName] =  useState();    
    const [chapter, setChapter] = useState<any>(() => null);
    const [chapterLead, setChapterLead] =  useState();
    const [status, setStatus] =  useState<any>();
    const [userOptions, setUserOption] = useState<any[]>(() => []);
    const navigate = useNavigate();
   

    let { id  } = useParams();
    React.useEffect(() => {      
        async function getChapterDetail() {            
            var chapter = await getChapter(id);                
            setChapter(chapter); 
            setName(chapter.name);
            setChapterLead(chapter.chapterLead.id);   
            setStatus(chapter.status);         
        }
        getChapterDetail();
        
    },[id]);

    React.useEffect(() => {
        async function getUserList() {          
            var usersResponse = await getTeamLeaders();
            const userOptions = usersResponse.users.map((item)=> { return {value: item.id, label: `${item.firstName} ${item.lastName}`  }} );     
            setUserOption(userOptions);          
        }        
        getUserList();
    },[]);


    const handleUserOptionChange = (option: any) => {       
        setChapterLead(option.value);
    };

    const handleStatusOptionChange = (option: any) => {        
        setStatus(option.value);
    };  

    const handleNameChange = (event: any) => {
        setName(event.target.value);        
    };
   
    const handleSubmit = async ()=> {                
        try{
            await updateChapter(id, { name: name, chapterLeadId: chapterLead, status: status });
            navigate('/chapters');
        }
        catch(err){          
            console.log(err);
        }
    }    

  return <>
  <DashboardLayout>
  <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Team edit page</div>                
            </div>
            
    {(chapter != null && userOptions.length > 0)?
    <Form>    
    <FormItem>
        <InputTextComponent type='text' 
                            label="Name" 
                            placeholder="type a name" 
                            fullWidth={true} 
                            value={name} 
                            onChildStateChange = {handleNameChange  }  />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Chapter lead"  
                           options={userOptions} 
                           selectedOption={userOptions.find((item) => item.value === chapterLead)} 
                           onChange={handleUserOptionChange} />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Status"  
                           options={statuses}  
                           selectedOption={statuses.find((item) => item.value === status)} 
                           onChange={handleStatusOptionChange} />
    </FormItem>    
    <FormButtonsItem>
        <Button  text='Save'  color='#2a7aeb' onClick={ () => handleSubmit() } />         
        <LinkButton onClick={ () => navigate('/teams') } text='Cancel'></LinkButton>       
    </FormButtonsItem>     
    </Form> : null }
    </DashboardLayout>
  </>
};

export default ChapterEditPage;