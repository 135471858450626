import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getChapters = () => {
  return axios.get(API_URL + "chapter", { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const createChapter = (payload: any) => {
    return axios.post(API_URL + "chapter", payload, { headers: authHeader() })
    .then((response)=> {    
      return response.data;
    });
};

export const getChapter = (id: string) => {
  return axios.get(API_URL + "chapter/details/"+id, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const updateChapter = (id:string, payload: any) => {
  return axios.put(API_URL + "chapter/" + id, payload, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};