import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../../components/modal/modal.component';
import CreateNewPosition from '../../../features/positions/create-position.feature';
import useModal from '../../../hooks/useModal';
import DashboardLayout from '../../../layouts/DashboardLayout';
import { deletePosition, getPositions } from '../../../services/positions.service';
import { convertDate } from '../../../utils/date';
import './styles/positions.style.css';

interface Props {
    children?: React.ReactNode
}
const PositionsPage: React.FunctionComponent<Props> = (props:Props) => {  
    const [positions, setPositions] = React.useState<any[]>([]);
    console.log(props);
    React.useEffect(()=> {
        async function getPositionsList() {
            const positions = await getPositions();
            setPositions(positions);
        }
        getPositionsList();
    },[]);

    const fetchPositions = async () => {
        const positions = await getPositions();
        setPositions(positions);
    }

    const navigate = useNavigate();
    const handleNavigate = async (url: string) => {
        navigate(url);
    }

    const handleDelete = async (id: string) => {
        await deletePosition(id);
        navigate('/settings/positions');
      };

    const {isShowing, toggle} = useModal();
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">settings page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> positions </div>
            </div>

            <div className="controls-page">
                <div>
                    <h1>All Positions</h1>
                </div>
                <div className="controls-list-page">
                    <div className="controls-new-team" onClick={toggle}>+ Add Position</div>
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="New Position"
                >
                    <CreateNewPosition fetchPositions={fetchPositions} title = 'Create Position' />
                </ModalComponent>

            </div>

            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>
                    <th className="team-id">Id</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {positions.length ?  positions.map((item, i)=> {
                        return (<tr className="team-tr" key={i}>
                        <td className="team-id-value">{i + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.description ? item.description : ' - '}</td>
                        <td>{convertDate(item.createdAt)}</td>
                        <td><span onClick={() => handleNavigate(`/settings/positions/edit/${item.id}`)}>Edit</span>&nbsp;<span onClick={() => handleDelete(item.id)} >Delete</span></td>
                        </tr>)
                    }) :null}
                
                </tbody>
            </table>
        </DashboardLayout>
    </>
}

export default PositionsPage;