import React from 'react';
import ReactDOM from 'react-dom';
import './styles/modal.style.css';

interface InputTextProps {
    isShowing: boolean;
    hide: any;
    title: string;
    children?: React.ReactNode
}

const Modal:React.FC<InputTextProps & { id?: string }>  = ({ id=1, isShowing, hide, title, children }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className={`modal-overlay ${id}-overlay`}/>
    <div className={`modal-wrapper ${id}-wrapper`} aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className={`modal ${id}`}>
        <div className="modal-header">
          <div>{title}</div>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          { children ? children : null }
        </div>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;
