import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";
export const getWorkflowItems =  (onboardingId: string) => {
    return  axios.get(API_URL + "task/workflow/"+ onboardingId, { headers: authHeader() });
}

export const getOffboardingWorkflowItems =  (onboardingId: string) => {
    return  axios.get(API_URL + "task/workflow/offboarding/"+ onboardingId, { headers: authHeader() });
}

export const getActionsByEmployeeId =  (employeeId: string) => {
    return  axios.get(API_URL + "task/workflow/actions/"+ employeeId, { headers: authHeader() });
}

export const createTask = (payload: any, boardingId: string) => {
    return axios.post(API_URL + "task/assign-task/"+ boardingId , payload, { headers: authHeader() });
};

export const createEmployeeTask = (payload: any, boardingId: string) => {
    return axios.post(API_URL + "task/assign-employee-task/"+ boardingId , payload, { headers: authHeader() });
};

export const removeAssignedTask = (taskId: string) => {
    return axios.delete(API_URL + "task/remove/" + taskId, { headers: authHeader() });
};
