import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from '../../../layouts/DashboardLayout';
import RemoveImage from "../../../assets/svg/gray/icons8-trash.svg";
import { useState } from "react";
import DropdownSelectBox from "../../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../../components/input/InputText.component";
import { updateWorkflow } from "../../../services/workflows.service";
import { getWorkflowDetails } from "../../../services/workflows.service";
import { getActions } from "../../../services/actions.service";
import LinkButton from '../../../components/LinkButton/LinkButton.component';
import Button from '../../../components/Buttons/Button.component';

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Form = styled.div`
    width: 50%;
`;

const FormButtonsItem = styled.div`
    display: flex;
    gap:10px;
    flex-wrap: nowrap;
    margin-top: 15px;
`;

const ItemsWrapper = styled.div`
    display:flex;
    flex-direction: column;
`;

const ItemList = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Item = styled.div`
    width: 47%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    background-color: #e3e5e8;
    align-items: center;
    padding-left: 10px;
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

const RemoveImageItem = styled.img`
    opacity: 0.5;
    width: 12px;
`;

const ItemName =  styled.div``;
const ItemClose =  styled.div`
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    border-left: 1px solid #6e696c;

`;

const NoContent = styled.div`
    font-size: 12px;
`;

const ItemsTitle = styled.div`
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
`;

function EditWorkflowPage() {   
    
    const [workflowName, setWorkflowName] = useState('');    
    const [actionOptions, setActionOptions] = useState<any[]>([]);    
    const [selectedActionOptions, setSelectedActionOptions] = useState<any[]>([]);    

    const navigate = useNavigate(); 
    
    let { id  } = useParams();
    React.useEffect(() => {      
        async function getWorkflow() {            
            var workflow = await getWorkflowDetails(id);                
            setWorkflowName(workflow.name);           
            const selectedActionOptionsItems = workflow.workflowActions.map((item)=> { return {value: item.action.id, label: item.action.name }} );        
            setSelectedActionOptions(selectedActionOptionsItems);                        
        }
        getWorkflow();        
    },[id]);

    React.useEffect(() => {      
        async function getAllActions() {            
            var actions = await getActions();        
            const actionOptionsItems = actions.map((item)=> { return {value: item.id, label: item.name }} );        
            setActionOptions(actionOptionsItems);                                    
        }
        getAllActions();        
    },[]);
    
    const handleWorkflowName = (event: any) => {
        setWorkflowName(event.target.value);
    };

    const handleOptionActionChange = (option: any) => {    
        const newItem: any = {value: option.value, label: option.label};
        setSelectedActionOptions(prevItems => prevItems.concat(newItem));        
    };   
    
    const handleSelectedActionClick = (id: string) => {
        // Create a new array by filtering out the clicked item
        const updatedItems = selectedActionOptions.filter((item) => item.value !== id);
        setSelectedActionOptions(updatedItems);
    };

    const handleSubmit = async ()=> {       
        await updateWorkflow(
        id, 
        { 
            name: workflowName,            
            actions: selectedActionOptions.map((item)=> item.value),
         });
         navigate('/workflow');      
    }


  return <>
    <DashboardLayout>
  <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Workflow edit page</div>                
            </div>
            
    {(actionOptions.length > 0)?
    <Form>
    <FormItem>
        <InputTextComponent onChildStateChange = {handleWorkflowName} value={workflowName} fullWidth={true} label="Name" type="text"  placeholder="type workflow name" />
    </FormItem>    
    <FormItem>
    <DropdownSelectBox label="Actions"  options={actionOptions} selectedOption={actionOptions[0]} onChange={handleOptionActionChange} />
    </FormItem>
    <ItemsWrapper>
        {selectedActionOptions && selectedActionOptions.length ? <ItemsTitle>Choosen Items { selectedActionOptions.length }</ItemsTitle> : <NoContent>please choose actions</NoContent> }
    <ItemList>
        { selectedActionOptions && selectedActionOptions.length ? selectedActionOptions.map((item: any, i: any) => <Item key={item.value}><ItemName>{ item.label }</ItemName><ItemClose><RemoveImageItem onClick={()=>handleSelectedActionClick(item.value)} src = { RemoveImage } /></ItemClose></Item>) : null }
    </ItemList>
    </ItemsWrapper>
    <FormButtonsItem>
        <Button  text='Save'  color='#2a7aeb' onClick={ () => handleSubmit() } />         
        <LinkButton onClick={ () => navigate('/workflow') } text='Cancel'></LinkButton>              
    </FormButtonsItem>
    </Form> : null }
    </DashboardLayout>
  </>
};

export default EditWorkflowPage;