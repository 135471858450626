import * as React from 'react';
import styled from "styled-components";
import ProfileImageComponent from '../../../components/ProfileImage/ProfileImage';
import { BoardingStatus } from './Status.enun';

const Iteam = styled.tr`
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    &:hover {
        cursor: pointer;
        background-color: #dbdbdb;
    }
`;

const IdIteam = styled.td`
    width: 20px;
`;

const ItemFname = styled.td`
    display: flex;
    margin-top: 10px;
    width: 220px;
`;

const Fname = styled.div`
    padding: 5px;
    padding-left: 10px;
`;

const SectionItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProfileImage = styled.div``;

const EmailItem = styled.div`
    padding-left: 10px;
    font-size: 13px;
    color: #ababab;
    padding-bottom: 7px;
`;

interface Props {
    id: string | number;
    firstName: string;
    lastName: string;
    position: string;
    status: BoardingStatus;
    verified: string;
    team: string;
    chapterLead: string;
    createdAt: string;
    onClick?: React.MouseEventHandler;
}
const EmployeeTableItem: React.FunctionComponent<Props> = ({ id, firstName, lastName, status, verified,  position, chapterLead, team, createdAt, onClick }) => {
    return <Iteam onClick={onClick}>
            <IdIteam></IdIteam>
            <ItemFname>

            <ProfileImage>
                <ProfileImageComponent firstName={ firstName } lastName = {lastName || ''} />
            </ProfileImage>
                <SectionItem>
                <Fname>{ firstName } { lastName }</Fname> <EmailItem>{ position }</EmailItem></SectionItem></ItemFname>
            <td>{ status }</td>
            <td> { chapterLead } </td>
            <td> { team } </td>
            <td> { verified } </td>
            <td>{  createdAt }</td>
        </Iteam>
}

export default EmployeeTableItem;
