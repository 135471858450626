import styled from "styled-components";
import * as React from "react";

const SearchInput = styled.input`
    width: 200px;
    border: 1px solid #f2edf2;
    padding: 8px;
    border-radius: 0px;
    &:focus {
        width: 300px;
        outline: none;
    }
`;


const SearchBoxComponent = ({ placeholder, sendDataToParent })=> {

    const handleChange = (event) => {
        const newText = event.target.value;
        sendDataToParent(newText);
    };

    return (<>
        <SearchInput onChange={handleChange} placeholder={placeholder}/>
    </>);
};

export default SearchBoxComponent;
