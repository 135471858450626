import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getActions } from '../../services/actions.service';
import ActionsTableIteam from './actions-table-items';
import ActionsTable from './actions-table.feature';
import { TabsEnum } from './workflow-table.feature';
import { deleteAction } from '../../services/actions.service';
import {transformRevertibleString} from "../../utils/revertible-format";
import {getWorkflows} from "../../services/workflows.service";

interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType: TabsEnum;
}

const ActionsFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls, tabType }) => {
    const [actions, setActions] = React.useState<any[]>([]);
    const [workflows, setWorkflows] = React.useState<any[]>([]);
    const navigate = useNavigate();
   
    React.useEffect(()=> {
        async function getActionsList() {
            const actions = await getActions();
            setActions(actions);
        }
        getActionsList();
    },[]);

    const actionsFetch = async () => {
        console.log(workflows);
        const actions = await getActions();
        setActions(actions);
    };

    const workflowsFetch = async () => {
        const workflows = await getWorkflows();
        console.log(workflows);
        setWorkflows(workflows.data);
    };

    const handleEdit = async (id: string) => {
        navigate('/action/edit/' + id);       
    };
    const handleDelete = async (id: string) => {
       await deleteAction(id);
       navigate('/workflow');
     };
    return <>
        <ActionsTable 
            title = { title } 
            showControls = { showControls } 
            showTitle = { showTitle } 
            showSearch = { showSearch }
            tabType = { tabType }
            actionsFetch = {actionsFetch}
            workflowsFetch={workflowsFetch}
        >
           {actions ? actions.map((item, i)=> {
               return  <ActionsTableIteam 
               id={i + 1} 
               key={item.id}
               name={item.name}
               status="ACTIVE"
               actionType={item.actionType}
               revertible={ transformRevertibleString(item.revertible) }
               owners={item.assignee.firstName}
               createdAt={item.createdAt}
               onEdit={() => handleEdit(item.id)} 
               onDelete={() => handleDelete(item.id)} />
           }) : null }
     </ActionsTable>   
    </>
}

export default ActionsFeature;