import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {getUsers} from "../../../services/user.service";
import { convertDate } from "../../../utils/date";
import EmployeeTableIteam from "./employee-table-items.feature";
import EmployeeTable from "./employee-table.feature";
import { TabsEnum } from './onboarding-table.feature';
interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType?: TabsEnum;
}

const EmployeeFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls, tabType }) => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyWord] = useState("");
    const handleNavigate = async (url: string) => {
        navigate(url);
    }
    const [users, setUsers] = useState<any[]>([]);

    const handleSendData = async (data: string)=> {
        console.log("EES", data);
        setSearchKeyWord(data);
        const employees = await getUsers(searchKeyword);
        setUsers(employees.users);
    }

    const fetchEmployees = async () => {
        const employees = await getUsers(searchKeyword);
        setUsers(employees.users);
    }

    useEffect(()=> {
        async function getChapterList() {
            const employees = await getUsers(searchKeyword);
            setUsers(employees.users);
        }
        getChapterList();
    }, [searchKeyword]);

    return <>
        <EmployeeTable
            title = { title }
            showControls = { showControls }
            showTitle = { showTitle }
            showSearch = { showSearch }
            tabType = { tabType }
            sendDataToParent={handleSendData}
            fetchEmployees={ fetchEmployees }
            fetchOnboardings={fetchEmployees}
        >
            {users.length ? users.map((item, i)=> {
                return <EmployeeTableIteam onClick = { ()=> handleNavigate(`${item.id}/view`)}
                    id={i + 1}
                    key={i + 1}
                    position="Developer"
                    firstName={item.firstName}
                    lastName={item.lastName}
                    chapterLead = { item.isChapterLead ? "Lead" : "-" }
                    team = {item.teams ? item.teams.name : "-"}
                    status={item.status.toUpperCase()}
                    verified = {item.verified.toUpperCase()}
                    createdAt={convertDate(item.createdAt)}
                ></EmployeeTableIteam>
            }) : null }
        </EmployeeTable>
    </>
}

export default EmployeeFeature;
