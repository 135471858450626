import { useState, useEffect } from "react";
import { getOnboardings } from "../../../services/onboardings.service";
import { convertDate } from "../../../utils/date";
import OnboardingTableIteam from './onboarding-table-items.feature';
import OnboardingTable, { TabsEnum } from './onboarding-table.feature';
import {useNavigate} from "react-router-dom";
import {EmptyBox} from "@rocket-desert/shared-ui";

interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType?: TabsEnum;
}
const OnboardingFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls, tabType }) => {
    const navigate = useNavigate();
    const [onboardings, setOnboardings] = useState<any>([]);
    const handleNavigate = async (url: string) => {
        navigate(url);
    }
    const fetchOnboardings = async () => {
        const onboardings = await getOnboardings();
        setOnboardings(onboardings);
    }
    useEffect(()=> {
        const fetchOnboardings =  async ()=> {
            const onboardings = await getOnboardings();
            setOnboardings(onboardings);
        }
        fetchOnboardings();
    },[]);

    return <>
        { onboardings.length ?
        <OnboardingTable
            title = { title }
            showControls = { showControls }
            showTitle = { showTitle }
            showSearch = { showSearch }
            tabType = { tabType }
            fetchOnboardings={fetchOnboardings}
        >
            {onboardings.length ? onboardings.map((item, i)=> {
                return <OnboardingTableIteam
                    id={i + 1}
                    key={i + 1}
                    workflow={item.workflow}
                    percentage={item.percentage}
                    position={item.position}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    status={item.status}
                    team={item.team}
                    createdAt={convertDate(item.createdAt)}
                    onClick={()=> handleNavigate(`/boarding/${item.id}`)}
                ></OnboardingTableIteam>
            }) : null }
        </OnboardingTable> : <EmptyBox message={`There is no any onboarding`} /> }
    </>
}

export default OnboardingFeature;
