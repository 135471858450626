import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileTitle.styles.css';
import SymbolBox from '../../components/SymbolBox/SymbolBox.component';

interface ProfileProps{
    symbols: string;
 }

function ProfileTitleComponent ({ symbols }: ProfileProps) {
    const [menuVisible, setMenuVisible] = React.useState(false);
    const navigate = useNavigate();
    const handleLogout= () => {
        localStorage.clear();
        navigate('/');        
    }

    const handleBoxClick = () => { 
          setMenuVisible(!menuVisible);               
    }

    return (<>
    
    <nav>
        <div><SymbolBox key="user-profile" onClick={()=>handleBoxClick()} symbol={symbols} color="#5d4dab" />
        {(menuVisible)?
            <ul className='user-menu'>
               <li><a href="/settings">Settings</a></li>
               <li><a href="/" onClick={handleLogout}>Sign out</a></li>
            </ul> : null }
        </div>   
    </nav> 
  </>);
}


export default ProfileTitleComponent;
