import * as React from 'react';
import { useParams } from 'react-router-dom';
import ModalComponent from '../../components/modal/modal.component';
import StartOffboardingFeature from '../../features/boarding/onboarding/flow/start-offboarding.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getEmployeeById, removeEmployee } from '../../services/user.service';
import { convertDate } from '../../utils/date';
import './styles/employees.style.css';
import styled from "styled-components";
import {useEffect, useState} from "react";
import {getActions} from "../../services/actions.service";
import {getActionsByEmployeeId, removeAssignedTask} from "../../services/tasks.service";
import AssignEmployeeTaskFeature from "../../features/task/assigne-employee-task.feature";
interface Props {
    children?: React.ReactNode
}
const EmployeeDetailsPage: React.FunctionComponent<Props> = (props:Props) => {
    console.log(props);
    const [currentEmployee, setCurrentEmployee] = React.useState<any>();
    const [workflowTasks, setWorkflowTasks] = useState<any[]>([]);
    const [actions, setActions] = React.useState<any[]>([]);
    const modal1 = useModal(false);
    const modal2 = useModal(false);
    let { employeeId } = useParams();

    React.useEffect(() => {
        async function getActionsList() {
            const actions = await getActions();
            setActions(actions);
        }
        getActionsList();
    }, []);

    React.useEffect(() => {
        async function getActionsList() {
            await getActions();
        }
        getActionsList();
    }, []);

    useEffect(()=> {
        async function getWorkflowTasks() {
            const workflowTasks = await getActionsByEmployeeId(employeeId);
            setWorkflowTasks(workflowTasks.data)
        }
        getWorkflowTasks();
    },[employeeId]);

    console.log(employeeId);
    React.useEffect(() => {
        async function getEmployeeId() {
            const employee = await getEmployeeById(employeeId);
            setCurrentEmployee(employee.data);
        }
        getEmployeeId();
    }, [employeeId]);

    async function removeUser(employeeId: string) {
        await removeEmployee(employeeId);
        alert("ok")
    }

    async function removeAssigned(taskId: string) {
        await removeAssignedTask(taskId);
    }

    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Employee Page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> { currentEmployee ? `${currentEmployee.firstName} ${currentEmployee.lastName}`  : null } </div>
            </div>

            <div className="controls-page">
                <div>
                    <h1>Employee Details</h1>
                </div>
                <div className="controls-list-page">
                    <div className="controls-new-team" onClick={modal1.toggle}>+ Start Offboarding</div>
                </div>
                <ModalComponent
                    isShowing={modal1.isShowing}
                    hide={modal1.toggle}
                    title="Start Offboarding"
                    id = "start-offboarding"
                >
                    <StartOffboardingFeature employeeId={employeeId} />
                 </ModalComponent>

            </div>
            {currentEmployee ?
            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>
                    <th>Employee</th>
                    <th>Created at</th>
                    <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                <tr className="team-tr">
                    <td> {currentEmployee.firstName}  {currentEmployee.lastName}</td>
                    <td> { convertDate(currentEmployee.createdAt) } </td>
                    <td> <div onClick={() =>removeUser(currentEmployee.id)}>Delete</div> </td>
                </tr>
                </tbody>
            </table> : null }

            <AssignedTasksWrapper>
                <TaskContainer>
                    <WorkflowTitle>Assigned Tasks</WorkflowTitle>
                    <AddTask onClick={modal2.toggle}>+ Assign</AddTask>
                </TaskContainer>
                <ModalComponent
                    id = "assign-task"
                    isShowing={modal2.isShowing}
                    hide={modal2.toggle}
                    title= "Assign"
                >
                <AssignEmployeeTaskFeature employeeId={ employeeId } actionsList={actions} />
                </ModalComponent>
                    <WorkflowTable>
                        <WorkflowHead>
                            <TaskTitleHead>Task name</TaskTitleHead>
                            <TaskStatusHead>Status</TaskStatusHead>
                            <TaskStartDateHead>Assigned Date</TaskStartDateHead>
                            <TaskDueDateHead>Due Date</TaskDueDateHead>
                            <TaskAssigneeHead>Assigned to</TaskAssigneeHead>
                            <TaskActionHead>Edit</TaskActionHead>
                        </WorkflowHead>
                        { workflowTasks ? workflowTasks.map((item, i)=> {

                            return <WorkflowRow key={i}>
                                <>
                                    <TaskTitle>{item.title}</TaskTitle>
                                    <TaskStatus>{item.taskStatus}</TaskStatus>
                                    <TaskStartDate>{convertDate(item.startDate)}</TaskStartDate>
                                    <TaskDueDate>{convertDate(item.dueDate)}</TaskDueDate>
                                    <TaskAssignee>{ item.assignee.firstName } {item.assignee.lastName}</TaskAssignee>
                                    <TaskAction>
                                        { item.taskStatus === 'COMPLETED' ?
                                            <RemoveDisabled>remove</RemoveDisabled> : <RemoveActive onClick={ () => removeAssigned(item.id) }>remove</RemoveActive> }
                                    </TaskAction>
                                </></WorkflowRow>})  : null }
                    </WorkflowTable>
                    Assets
            </AssignedTasksWrapper>
        </DashboardLayout>
    </>
}

const TaskContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const AssignedTasksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const WorkflowTitle = styled.div`
display: flex;
font-weight: bold;
`;


const WorkflowTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top:20px;
`;

const AddTask = styled.div`
cursor: pointer;
`;

const WorkflowRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
`;


const WorkflowHead = styled.div`

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  padding: 15px;
`;

const TaskTitle = styled.div`
  width: 200px;
`;
const TaskStatus = styled.div`
  width: 100px;
`;
const TaskStartDate = styled.div`
  width: 200px;
`;
const TaskDueDate = styled.div`
  width: 200px;
`;
const TaskAssignee = styled.div`
  width: 200px;
`;

const TaskTitleHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskStatusHead = styled.div`
  width: 100px;
  font-weight: bold;
`;
const TaskStartDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskDueDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskAssigneeHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskActionHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskAction = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveActive = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveDisabled = styled.div`
  width: 200px;
  color: gray;
`;
export default EmployeeDetailsPage;
