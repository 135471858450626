import styled from "styled-components";
import RemoveImage from "../../../assets/svg/gray/icons8-trash.svg";
import { useState } from "react";
import DropdownSelectBox from "../../../components/DropdownSelectBox/DropdownSelectBox.component";
import SentComponent from "../../../components/Sent/Sent.component";
import InputTextComponent from "../../../components/input/InputText.component";
import { createWorkflow } from "../../../services/workflows.service";
const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    min-width: 100px;
    max-width: 200px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

const ItemsWrapper = styled.div`
    display:flex;
    flex-direction: column;
`;

const ItemList = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Item = styled.div`
    width: 47%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    background-color: #e3e5e8;
    align-items: center;
    padding-left: 10px;
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;

const RemoveImageItem = styled.img`
    opacity: 0.5;
    width: 12px;
`;

const ItemName =  styled.div``;
const ItemClose =  styled.div`
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    border-left: 1px solid #6e696c;

`;

const NoContent = styled.div`
    font-size: 12px;
`;

const ItemsTitle = styled.div`
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
`;
interface InputTextProps{
    title?: string;    
    actionsList?: any;
    fetchData?: () => void;
 }

interface Items {
    id: string;
    name: string;
}


const CreateWorkflowFeature:React.FC<InputTextProps> = ({ title, actionsList, fetchData })=> {
    const [notSent, setnotSent] = useState(true);  
    
    const actionsListItems = actionsList.length ? actionsList.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Action"}];
    const [selectedActionsOption, setSelectedActionsOption] = useState(actionsListItems[0]);
    const [items, setItems] = useState<any[]>([]);


    const [workflowName, setWorkflowName] = useState('');

    const handleActionsOptionChange = (option: any) => {
        setSelectedActionsOption(option);
        const newItem: any = {id: option.value, name: option.label};
        setItems(prevItems => prevItems.concat(newItem));
    };


    const handleWorflowName = (event: any) => {
        setWorkflowName(event.target.value);
    };

    const handleSubmit = async () => {        
        await createWorkflow({ 
            name: workflowName,                   
            actions: items.map((item)=> item.id),
         });
        fetchData && fetchData();
        setnotSent(false);
    }    
    
    const handleItemClick = (id: string) => {
        // Create a new array by filtering out the clicked item
        const updatedItems = items.filter((item) => item.id !== id);
        console.log(title);
        setItems(updatedItems);
      };

  return <>
    {notSent ?  <div>
    <FormItem>
        <InputTextComponent onChildStateChange = {handleWorflowName} fullWidth={true} label="Workflow name" type="text"  placeholder="Workflow name" />
    </FormItem>    
    <FormItem>
    <DropdownSelectBox label="Choose an action"  options={actionsListItems} selectedOption={selectedActionsOption} onChange={handleActionsOptionChange} />
    </FormItem>
    <ItemsWrapper>
        {items && items.length ? <ItemsTitle>Choosen Items { items.length }</ItemsTitle> : <NoContent>please choose actions</NoContent> }
    <ItemList>
        { items && items.length ? items.map((item: Items, i: number) => <Item key={i}><ItemName>{ item.name }</ItemName><ItemClose><RemoveImageItem onClick={()=>handleItemClick(item.id)} src = { RemoveImage } /></ItemClose></Item>) : null }
    </ItemList>
    </ItemsWrapper>
    <FormItem>
        <Button onClick = { () => handleSubmit() }>Create Workflow</Button>
    </FormItem></div> : <SentComponent title = "Workflow has been created!" />}
  </>
};

export default CreateWorkflowFeature;