import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getOnboardings = async () => {
    try {
        return await axios.get(API_URL + "boarding/onboardings", { headers: authHeader() }).then(item=> item.data);
    } catch (error) {
        console.log("Error retrieving metrics:", error);
        throw error;
      }
};

export const getOffboardings = async () => {
    try {
        return await axios.get(API_URL + "boarding/offboardings", { headers: authHeader() }).then(item=> item.data);
    } catch (error) {
        console.log("Error retrieving metrics:", error);
        throw error;
      }
};

export const getOnboardingById =  (onboardingId: string) => {
    return  axios.get(API_URL + "boarding/onboarding/"+ onboardingId + "/details", { headers: authHeader() });
}

export const getOffboardingById =  (onboardingId: string) => {
    return  axios.get(API_URL + "boarding/offboarding/"+ onboardingId + "/details", { headers: authHeader() });
}

export const createOnboarding = (payload: any) => {
    return axios.post(API_URL + "boarding/create-onboarding", payload, { headers: authHeader() });
};

export const addEmployee = (payload: any) => {
    return axios.post(API_URL + "user/invite-user", payload, { headers: authHeader() });
};

export const startOffboarding = (payload: any) => {
    return axios.post(API_URL + "boarding/start-offboarding", payload, { headers: authHeader() });
};
