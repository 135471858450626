import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CreateButtonComponent from '../../components/Buttons/CreateButton.component';
import ModalComponent from '../../components/modal/modal.component';
import NewChapterFeature from '../../features/chapters/create-chapter.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getChapters } from "../../services/chapter.service";
import { getTeamLeaders } from "../../services/user.service";
import { convertDate } from "../../utils/date";
import './styles/chapters.style.css';

const ChaptersPage: React.FunctionComponent = () => {
    const {isShowing, toggle} = useModal();
    const [leaders, setLeaders] = useState<{id: number | string; firstName: string}[]>([]);
    const [chapters, setChapters] = useState<any[]>([]);
    const navigate = useNavigate();
    const handleNavigate = async (url: string) => {
        navigate(url);
    }

    useEffect((): void=> {
        (async function getLeaders(): Promise<void> {
            const users = await getTeamLeaders();
            setLeaders(users.users);
        })();
    },[]);

    const fetchChapters = async () => {
        const chapters = await getChapters();
        setChapters(chapters.chapters);
    }

    useEffect(()=> {
        async function getChapterList() {
            const chapters = await getChapters();
            setChapters(chapters.chapters);
        }
        getChapterList().then(r => console.log(r));
    }, []);



    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Chapters Page </div>
            </div>

            <div className="controls-page">
                <div>
                    <h1>All Chapters</h1>
                </div>
                <div className="controls-list-page">
                    <CreateButtonComponent textColor="#ffffff" color="#2a7aeb"  text="+ New Chapter" onClick={ toggle } />
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="New Chapter"
                >
                    <NewChapterFeature fetchChapters={fetchChapters} users={leaders} title = 'Create Chapter' />
                </ModalComponent>

            </div>

            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>
                    <th className="team-id">Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Chater Lead</th>
                    <th>Users</th>
                    <th>Created at</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                { chapters ? chapters.map((item, i)=> {
                    return <tr className="team-tr" key={item.id} onClick = { ()=> handleNavigate(`${item.id}/view`)}>
                    <td className="team-id-value">{i + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>{item.chapterLeader.firstName} {item.chapterLeader.lastName}</td>
                    <td>0 emp.</td>
                    <td>{ convertDate(item.createdAt)}</td>
                    <td><span onClick={(e) => { e.stopPropagation(); handleNavigate(`/chapters/edit/${item.id}`);}}>Edit</span></td>
                </tr>
                }) : null }
                </tbody>
            </table>
        </DashboardLayout>
    </>
}

export default ChaptersPage;