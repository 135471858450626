import * as React from 'react';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import './styles/setPassword.style.css';
import InputTextComponent from '../../components/input/InputText.component';
import { checkResetPasswordToken, setPassword } from '../../services/auth.service';
import { InfoMessageComponent, MessageTypes } from '../../components/InfoMessage/InfoMessage.component';
import { login } from '../../services/auth.service';

interface error{
  show: boolean,
  text?: string
}

function SetPasswordPage() {
  const [email, setEmail] = useState(''); 
  const [newPassword, setNewPassword] = useState('');
  const [confirmPssword, setConfirmPassword] = useState('');  
  const [error, setError] = useState<error>({ show:false, text:'' });  
  const [isLinkValid, setIsLinkValid] = useState(true);
  
  const navigate = useNavigate();

  let { token } = useParams();
  React.useEffect(() => {      
      async function checkToken() {            
          var response = await checkResetPasswordToken(token);          
          if(response.status === "notValid")
          setIsLinkValid(false);          
      }
      checkToken();
      
  },[token]);

  const handleSubmit = async () => {
    try 
    {      
      if(newPassword !== confirmPssword)
       {
          showErrorMessage("Passwords doesn't match");
          return;         
       }

      const response = await setPassword(token, newPassword);
        
      if(response.status === "Ok") 
      { 
        const user = await login(email, newPassword);
        (user.email)? navigate('/dashboard') : navigate('/')
      } 
      else 
        showErrorMessage("Password save failed!");       
    } 
    catch(e) 
    {    
      showErrorMessage("Password save failed!");
    }
  }

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    resetMessages();
  };

  const handlePasswordChange = (event: any) => {
    setNewPassword(event.target.value);
    resetMessages();
  };

  const handlePasswordConfirmChange = (event: any) => {
    setConfirmPassword(event.target.value);
    resetMessages();
  };  

  const showErrorMessage = (text: string) => {  
    setError({show:true, text: text});   
  };

  const resetMessages = () => {  
    setError({show:false, text:''});    
  };

  return (
    <div className="wrapper">
      <div className="leftSideWrapper">
          <div className="sideWrapper">
          <div className="logo">
              Rocket Desert
          </div>
          <div className="description">
            Maximizing Business Potential through Superior Onboarding Experiences
          </div>
          </div>
      </div>
      <div className="rightSide">
        <div className="loginWrapper">
              <div className="Texthead">
                <h3>Reset your password</h3>
                <span></span>
              </div>
              {(isLinkValid)?
              <div>
                <div className="responsive-head">
                  <h3>Reset your password</h3>
                  <span></span>
                </div>
              
                <div className="form">                
                    <InputTextComponent onChildStateChange={handleEmailChange}  label="Enter your email" type="email" />
                    
                    <InputTextComponent onChildStateChange={handlePasswordChange}  label="Enter your password" type="password" />

                    <InputTextComponent onChildStateChange={handlePasswordConfirmChange}  label="Password confirmation" type="password" />                  

                    {error.show ? <InfoMessageComponent text={error.text} type={MessageTypes.Error} /> : null}                   

                    <div className="loginButton">
                        <div className="loginBtn" onClick={handleSubmit}>Reset Password</div>
                    </div>

                </div>
              </div>: <InfoMessageComponent text='Password reset url not valid!' type={MessageTypes.Error} /> }
          </div>
      </div>    

    </div>
  );
}

export default SetPasswordPage;
