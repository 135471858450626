import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getAssetsCatalog = () => {
    return axios.get(API_URL + "assets", { headers: authHeader() })
        .then((response)=> {
            return response.data;
        });
};

export const getAssetById = (assetId: string) => {
    return axios.get(API_URL + "assets/" + assetId, { headers: authHeader() })
        .then((response)=> {
            return response.data;
        });
};

export const createAsset = (payload: any) => {
    return axios.post(API_URL + "assets/create-asset", payload, { headers: authHeader() })
        .then((response)=> {
            return response.data;
        });
};

export const assignAssetToEmployee = (payload: any) => {
    return axios.post(API_URL + "assets/create-assets-management", payload, { headers: authHeader() })
        .then((response)=> {
            return response.data;
        });
};