import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getWorkflows = () => {
  return axios.get(API_URL + "workflow", { headers: authHeader() });
};

export const getWorkflowDetails = (id:string) => {
  return axios.get(API_URL + "workflow/details/" + id, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const createWorkflow = (payload: any) => {
    return axios.post(API_URL + "workflow/create", payload, { headers: authHeader() });
};

export const updateWorkflow = (id:string, payload: any) => {
  return axios.put(API_URL + "workflow/" + id, payload, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const deleteWorkflow = (id:string) => {
  return axios.delete(API_URL + "workflow/" + id, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });;
};