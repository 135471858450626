import * as React from 'react';
import styled from "styled-components";
import CreateButtonComponent from '../../components/Buttons/CreateButton.component';
import SearchBoxComponent from '../../components/SearchBox/SearchBox.component';
import ModalComponent from '../../components/modal/modal.component';
import useModal from '../../hooks/useModal';
import CreateWorkflowFeature from './flow/create-workflow.feature';
import CreateActionFeature from './flow/create-action.feature';
import { getUsers } from '../../services/user.service';

const TableTitle = styled.table`
    font-weight: 600;
    margin-top: 30px;
    color: #474443;
`;

const Table = styled.table`
    width: 100%;
    padding: 10px;
    margin: 0;
    border-collapse: collapse;
    margin-top: 10px;
`;

const Thead = styled.thead`
    font-weight: 600;
    background-color: #f0f0f0;
    padding: 10px;
    height: 40px;
    color: #121111;
    border: 1px solid #e0e0e0;
    font-size: 13px;
`;

const ThItem = styled.th`
    text-align: center;
    min-width: 30px;
`;

const Fullname = styled.th`
    width: 220px;
`;

const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
`;

export enum TabsEnum {
    WORKFLOW,
    ACTION
}

interface Props {
    title?: string;
    children?: React.ReactNode;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType: TabsEnum;
    actionsFetch: () => void;
    workflowsFetch: () => void;
}
const ActionsTable: React.FunctionComponent<Props> = ({ title, children, showTitle, showControls, showSearch, tabType, actionsFetch, workflowsFetch }) => {
    const {isShowing, toggle} = useModal();
    const [users, setUsers] =  React.useState<any[]>([]);

    const handleDataFromChild = (data: string) => {
        console.log("From Child ", data);
    };

    React.useEffect(()=> {
        async function getUsersList() {
            var usersResponse: any = await getUsers();
            setUsers(usersResponse.users);
        }
        getUsersList();
    }, []);
    return <>
        <HeadingWrapper>
            {showTitle ? <div><TableTitle>{ title }</TableTitle></div> : null }
            {showSearch ? <SearchBoxComponent sendDataToParent={handleDataFromChild} placeholder="Search by workflow name..." /> : null }
            {title && showControls ?
                <CreateButtonComponent
                    textColor="#ffffff"
                    color="#2a7aeb"
                    text={"+ Create Action"}
                    onClick={toggle}
                />
            : null }
        </HeadingWrapper>
        <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title={title!}
                >
                { tabType === TabsEnum.WORKFLOW ? <CreateWorkflowFeature fetchData={workflowsFetch} /> : <CreateActionFeature fetchData={actionsFetch} users={users} /> }
        </ModalComponent>
        <Table cellPadding="0" cellSpacing="0">
            <Thead>
                <tr>
                    <ThItem>Id</ThItem>
                    <Fullname>Name</Fullname>
                    <th>Status</th>
                    <td>Owner</td>
                    <td>Revertible</td>
                    <td>Action Type</td>
                    <th>Created at</th>
                    <th>Actions</th>
                </tr>
            </Thead>
            <tbody>
            { children }
            </tbody>
        </Table>
    </>
}

export default ActionsTable;
