import { useState } from "react";
import styled from "styled-components";
import InputTextComponent from "../../components/input/InputText.component";
import SentComponent from "../../components/Sent/Sent.component";
import TextBoxComponent from "../../components/TextBox/TextBox.component";
import { createPositions } from "../../services/positions.service";

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    width: 100px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    users?: any[];
    fetchPositions?: () => void;
 }

const CreateNewPosition:React.FC<InputTextProps> = ({ title, users, fetchPositions })=> {

    const [notSent, setnotSent] = useState(true);
    const [teamName, setTeamName] =  useState('');
    const [description, setDescription] = useState('');
    console.log(title, users);
    const handleChapterLeader = (event: any) => {
        setTeamName(event.target.value);
    };

    const handleDescription = (event: any) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async()=> {
        setnotSent(false);
        await createPositions({ name: teamName, description: description });
        fetchPositions && fetchPositions();
    }
 
  return <>
    {notSent ? <div><FormItem>
        <InputTextComponent onChildStateChange = {handleChapterLeader } fullWidth={true} label="Position" type="text"  placeholder="Position name" />
    </FormItem>
    <FormItem>
        <TextBoxComponent fullWidth={true} onChildStateChange = {handleDescription}  placeholder="Position Description" />
    </FormItem>
    <FormItem>
        <Button onClick = { () => handleSubmit() }>Save</Button>
    </FormItem></div> : <SentComponent title = "New team has been created!" /> }
  </>
};

export default CreateNewPosition;
