import * as React from 'react';
import DashboardLayout from '../../../layouts/DashboardLayout';
import './styles/boarding.details.css';
import {useEffect, useState} from "react";
import {getOnboardingById} from "../../../services/onboardings.service";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {convertDate} from "../../../utils/date";
import {getWorkflowItems, removeAssignedTask} from "../../../services/tasks.service";
import useModal from "../../../hooks/useModal";
import ModalComponent from "../../../components/modal/modal.component";
import AssignTaskFeature from "../../../features/task/assign.feature";
import {getActions} from "../../../services/actions.service";
import {EmptyBox} from "@rocket-desert/shared-ui";
interface Props {
    children?: React.ReactNode
}

const BoardingContainer = styled.div`
  border: 1px solid #f7f7f7;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;


const BasicInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const BoxItem = styled.div`
  min-width: 100px;
  height: 60px;
  padding: 10px;
  border: 1px solid #f7f7f7;
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const BoxTitle = styled.div`
  font-weight: bold;
`;
const BoxContent = styled.div`
  padding-top: 15px;
`;

const WorkflowBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const WorkflowTitle = styled.div`
  display: flex;
  font-weight: bold;
`;

const WorkflowTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top:20px;
`;

const WorkflowRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
`;


const WorkflowHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  padding: 15px;
`;

const TaskTitle = styled.div`
  width: 200px;
`;
const TaskStatus = styled.div`
  width: 100px;
`;
const TaskStartDate = styled.div`
  width: 200px;
`;
const TaskDueDate = styled.div`
  width: 200px;
`;
const TaskAssignee = styled.div`
  width: 200px;
`;

const TaskTitleHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskStatusHead = styled.div`
  width: 100px;
  font-weight: bold;
`;
const TaskStartDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskDueDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskAssigneeHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskActionHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AddTask = styled.div`
  cursor: pointer;
`;

const TaskAction = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveActive = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveDisabled = styled.div`
  width: 200px;
  color: gray;
`;

const OnboardingDetailsPage: React.FunctionComponent<Props> = (props:Props) => {
    const {isShowing, toggle} = useModal();
    const [onboarding, setOnboarding] = useState<any>();
    const [workflowTasks, setWorkflowTasks] = useState<any[]>([]);
    let { boardingId } = useParams();
    console.log(props);
    async function removeAssigned(taskId: string) {
        await removeAssignedTask(taskId);
       fetchBoardings();
    }

    useEffect(()=> {
        async function getOnboarding() {
            const onboarding = await getOnboardingById(boardingId);
            console.log(onboarding.data);
            setOnboarding(onboarding.data)
        }
        getOnboarding();
    },[boardingId]);

    const [actions, setActions] = React.useState<any[]>([]);

    React.useEffect(() => {
        async function getActionsList() {
            const actions = await getActions();
            setActions(actions);
        }
        getActionsList();
    }, []);

    const fetchBoardings = async () => {
        const workflowTasks = await getWorkflowItems(boardingId);
        setWorkflowTasks(workflowTasks.data)
    }

    useEffect(()=> {
        async function getWorkflowTasks() {
            const workflowTasks = await getWorkflowItems(boardingId);
            setWorkflowTasks(workflowTasks.data)
        }
        getWorkflowTasks();
    },[boardingId]);

    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Onboarding</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"></div>
            </div>

            <div className="controls-page">
                {onboarding ?
                <div>
                    <h1>{onboarding.firstName} {onboarding.lastName}</h1>
                </div> : null }


            </div>
            {onboarding ?
            <BoardingContainer>
                <BasicInfo>
                    <BoxItem>
                        <BoxTitle>Position</BoxTitle>
                        <BoxContent>{onboarding.position}</BoxContent>
                    </BoxItem>
                    <BoxItem>
                        <BoxTitle>Team</BoxTitle>
                        <BoxContent>{onboarding.team}</BoxContent>
                    </BoxItem>
                    <BoxItem>
                        <BoxTitle>Status</BoxTitle>
                        <BoxContent>{onboarding.status}</BoxContent>
                    </BoxItem>
                    <BoxItem>
                        <BoxTitle>Progress</BoxTitle>
                        <BoxContent>{onboarding.percentage}%</BoxContent>
                    </BoxItem>
                    <BoxItem>
                        <BoxTitle>Start Date</BoxTitle>
                        <BoxContent>{ convertDate(onboarding.createdAt)}</BoxContent>
                    </BoxItem>
                </BasicInfo>
                <WorkflowBox>
                    <TaskContainer>
                        <WorkflowTitle>{ workflowTasks.length ? onboarding.workflow : null }</WorkflowTitle>
                        <AddTask onClick={toggle}>+ Assign</AddTask>
                    </TaskContainer>
                    <ModalComponent
                        isShowing={isShowing}
                        hide={toggle}
                        title= "Assign"
                    >
                       <AssignTaskFeature fetchBoardings={fetchBoardings} boardingId={ boardingId } actionsList={actions} />
                    </ModalComponent>
                    {workflowTasks.length ?
                        <WorkflowTable>
                            <WorkflowHead>
                            <TaskTitleHead>Task name</TaskTitleHead>
                            <TaskStatusHead>Status</TaskStatusHead>
                            <TaskStartDateHead>Assigned Date</TaskStartDateHead>
                            <TaskDueDateHead>Due Date</TaskDueDateHead>
                            <TaskAssigneeHead>Assigned to</TaskAssigneeHead>
                            <TaskActionHead>Edit</TaskActionHead>
                            </WorkflowHead>
                            { workflowTasks ? workflowTasks.map((item, i)=> {

                               return <WorkflowRow key={i}>
                                    <>
                                        <TaskTitle>{item.title}</TaskTitle>
                                        <TaskStatus>{item.taskStatus}</TaskStatus>
                                        <TaskStartDate>{convertDate(item.startDate)}</TaskStartDate>
                                        <TaskDueDate>{convertDate(item.dueDate)}</TaskDueDate>
                                        <TaskAssignee>{ item.assignee.firstName } {item.assignee.lastName}</TaskAssignee>
                                        <TaskAction>
                                            { item.taskStatus === 'COMPLETED' ?
                                                <RemoveDisabled>remove</RemoveDisabled> : <RemoveActive onClick={ () => removeAssigned(item.id) }>remove</RemoveActive> }
                                        </TaskAction>
                                    </></WorkflowRow>})  : null}
                        </WorkflowTable> : <EmptyBox message='There is no any tasks' /> }
                </WorkflowBox>
            </BoardingContainer>: null }
        </DashboardLayout>
    </>
}

export default OnboardingDetailsPage;
