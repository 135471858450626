import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getActions = () => {
  return axios.get(API_URL + "action", { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const getActionDetails = (id:string) => {
  return axios.get(API_URL + "action/details/" + id, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const createAction = (payload: any) => {
    return axios.post(API_URL + "action/create", payload, { headers: authHeader() })
    .then((response)=> {    
      return response.data;
    });
};

export const updateAction = (id:string, payload: any) => {
  return axios.put(API_URL + "action/" + id, payload, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const deleteAction = (id:string) => {
  return axios.delete(API_URL + "action/" + id, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });;
};

