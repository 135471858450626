import * as React from 'react';
import { getOffboardings } from '../../../services/onboardings.service';
import { convertDate } from '../../../utils/date';
import OnboardingTableIteam from './onboarding-table-items.feature';
import OnboardingTable from './onboarding-table.feature';
import {useNavigate} from "react-router-dom";
import { EmptyBox } from '@rocket-desert/shared-ui';
interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
}

export const offboardingUsersList: any[] = [];

const OffboardingFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls }) => {
    const [offboardings, setOffboardings] = React.useState<any>([]);
    const navigate = useNavigate();
    const handleNavigate = async (url: string) => {
        navigate(url);
    }
    React.useEffect(()=> {
        async function getOffboardingsList() {
            const offboardings = await getOffboardings();
            setOffboardings(offboardings);
        }
        getOffboardingsList();
    },[]);
    return <>
        { offboardings.length ?
        <OnboardingTable
            title = { title }
            showControls = { showControls }
            showTitle = { showTitle }
            showSearch = { showSearch }
        >
           {offboardings.length ? offboardings.map((item, i)=> {
               console.log("Offboarding", item);
                return <OnboardingTableIteam
                    id={i + 1}
                    key={item.id}
                    workflow="General"
                    percentage={item.percentage}
                    position={item.position.name}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    status={item.status}
                    team={item.team.name}
                    onClick={()=> handleNavigate(`/offboarding/${item.id}`)}
                    createdAt={ convertDate(item.createdAt)}
                ></OnboardingTableIteam>
            }) : null }
     </OnboardingTable> : <EmptyBox message="There is no any offboardings" /> }
    </>
}

export default OffboardingFeature;
