import * as React from 'react';
import { useParams } from 'react-router-dom';
import ModalComponent from '../../components/modal/modal.component';
import AddEmployeeFeature from '../../features/boarding/onboarding/flow/add-employee.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getPositions } from '../../services/positions.service';
import {getTeamById, getTeams, removeEmployeeFromTeam} from '../../services/team.service';
import { getWorkflows } from '../../services/workflows.service';
import { convertDate } from '../../utils/date';
import './styles/teams.style.css';
import styled from "styled-components";
import ProfileImageComponent from "../../components/ProfileImage/ProfileImage";
interface Props {
    children?: React.ReactNode
}

const TeamMemberTable = styled.table`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Thead = styled.thead`
  width: 100%;
  display: flex;
  font-weight: 600;
  background-color: #f0f0f0;
  height: 50px;
  align-items: center;
  color: #121111;
  border: 1px solid #e0e0e0;
  font-size: 13px;
`;

const HeadTrow = styled.tr`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeadTh = styled.th`
  width: 100%;
  display: flex;
  padding-left: 20px;
`;

const HeadThProfile = styled.th`
  width: 100px;
  padding-left: 30px;
`;


const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TeamTdProfile = styled.td`
  width: 100px;
  padding-left: 20px;
`;

const TeamTr = styled.tr`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  align-items: center;
`;

const TeamTd = styled.td`
  text-align: left;
  width: 100%;
  padding-left: 20px;
`;

const RemoveButton = styled.div`
  cursor: pointer;
`;

const TeamsDetailsPage: React.FunctionComponent<Props> = (props:Props) => {
    const [workflows, setWorkflows] = React.useState<any[]>([]);
    const [positions, setPositions] = React.useState<any[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);
    const [currentTeam, setCurrentTeam] = React.useState<any>();

    let {teamId} = useParams();
    React.useEffect(() => {
        async function getTeamId() {
            const team = await getTeamById(teamId);
            console.log("INFO", team);
            setCurrentTeam(team);
        }

        getTeamId();
    }, [teamId]);


    async function removeEmployeeTeam(teamId: string, employeeId: string) {
        await removeEmployeeFromTeam(teamId, employeeId);
    }


    React.useEffect(() => {
        async function getPositionsList() {
            const positions = await getPositions();
            console.log("positions", positions);
            setPositions(positions);
        }
        getPositionsList();
    }, []);

    React.useEffect(() => {
        async function getWorkflowList() {
            const workflows = await getWorkflows();
            console.log(workflows);
            setWorkflows(workflows.data);
        }
        getWorkflowList();
    }, []);


    React.useEffect(() => {
        async function getChapterList() {
            const teams = await getTeams();
            console.log(teams);
            setTeams(teams);
        }
        getChapterList();
    }, []);

    const {isShowing, toggle} = useModal();
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Teams Page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> { currentTeam ? currentTeam.name : null } </div>
            </div>

            <div className="controls-page">
                <div>
                    <h1>Team Details</h1>
                </div>
                <div className="controls-list-page">
                    <div className="controls-new-team" onClick={toggle}>+ Invite members</div>
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="Invite members"
                >
                    <AddEmployeeFeature teams={teams} positions={positions} workflows={workflows} />
                 </ModalComponent>

            </div>
            {currentTeam ? <><table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>
                    <th className="team-id">Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Lead</th>
                    <th>Users</th>
                    <th>Onboardings</th>
                    <th>Offboardings</th>
                    <th>Created at</th>
                    </tr>
                </thead>
                <tbody>
                <tr className="team-tr">
                    <td className="team-id-value">1</td>
                    <td>{currentTeam.name}</td>
                    <td>{currentTeam.status}</td>
                    <td>{currentTeam.teamLead.firstName} {currentTeam.teamLead.lastName}</td>
                    <td>{currentTeam.members.length} employee</td>
                    <td>{currentTeam.onboardings.length || 0} employee</td>
                    <td>{currentTeam.offboarding.length || 0} employee</td>
                    <td>{ convertDate(currentTeam.createdAt)}</td>
                </tr>
                </tbody>
            </table>

                <TeamMemberTable>
                    <Thead>
                        <HeadTrow>
                            <HeadThProfile></HeadThProfile>
                            <HeadTh>First name </HeadTh>
                            <HeadTh>Last name</HeadTh>
                            <HeadTh>Position</HeadTh>
                            <HeadTh>Created At</HeadTh>
                            <HeadTh>Remove from the team</HeadTh>
                        </HeadTrow>
                    </Thead>
                    <Tbody>
                    { currentTeam.members.map((item: any, i: number) => {
                        return <TeamTr key={i}>
                            <TeamTdProfile>
                                <ProfileImageComponent firstName={ item.firstName } lastName = { item.lastName || ''} />
                            </TeamTdProfile>
                            <TeamTd>{ item.firstName}</TeamTd>
                            <TeamTd>{ item.lastName}</TeamTd>
                            <TeamTd>{ item.position || '-'}</TeamTd>
                            <TeamTd>{  convertDate(item.createdAt)}</TeamTd>
                            <TeamTd> <RemoveButton onClick={() => removeEmployeeTeam(teamId, item.id)}>Remove</RemoveButton> </TeamTd>
                        </TeamTr>
                    }) }
                    </Tbody>
                </TeamMemberTable></>
                : null }
        </DashboardLayout>
    </>
}

export default TeamsDetailsPage;
