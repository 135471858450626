import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authHeader from "../services/authHeader.service";
import { API_URL } from "../services/auth.service";
const useAuthorization = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();
  const api_url = API_URL;
  const redirectTo = "/";
  const authorized = true;
  useEffect(() => {
      
    const fetchAuthorization = async () => {
      try {
        const response = await fetch(API_URL + "auth/me", 
            { headers: authHeader() 
        });
        if (response.ok) {
          setIsAuthorized(true);
        } else {
          navigate(redirectTo);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (authorized) {
      fetchAuthorization();
    } else {
        navigate(redirectTo);
    }
  }, [authorized, navigate, redirectTo, api_url]);

  return isAuthorized;
};

export default useAuthorization;
