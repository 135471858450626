import axios from "axios";
import authHeader from "./authHeader.service";

export const API_URL = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost:3000';
export const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

export const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

export const getEmployeeById = (userId: string) => {
  return axios.get(API_URL + "/user/members/"+userId, { headers: authHeader() });
};



export const getUsers = (searchKeyword?: string) => {
  const keyword = searchKeyword ? searchKeyword : "";
  return axios.get(API_URL + "/user/members?searchKeyword="+ keyword, { headers: authHeader() })
  .then((response)=> {
    return response.data;
  });
};

export const searchUser = (searchKeyword?: string) => {
  const keyword = searchKeyword ? searchKeyword : "";
  return axios.get(API_URL + "/user/search?query="+ keyword, { headers: authHeader() });
};



export const getTeamLeaders =  () => {
  return  axios.get(API_URL + "/user/team-leaders", { headers: authHeader() })
  .then((response)=> {
    return response.data;
  });
}

export const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

export const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

export const removeEmployee = (employeeId: string) => {
  return axios.put(API_URL + "/user/" + employeeId + "/delete", {}, { headers: authHeader() });
};

export const searchEmployee = (searchKeyword: string) => {
  return axios.put(API_URL + "/user/search?query=" + searchKeyword, { headers: authHeader() });
};



