import * as React from 'react';
import styled from "styled-components";
import ProfileImageComponent from '../../../components/ProfileImage/ProfileImage';
import { CalculatePercentageStatus, CalculateStatusColor } from '../../../libs/CalculatePercentageStatus';
import { BoardingStatus } from './Status.enun';

const Iteam = styled.tr`
    height: 50px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    &:hover {
        cursor: pointer;
        background-color: #dbdbdb;
    }
`;

const IdIteam = styled.td`
    text-align: center;
    width: 0px;
`;

const ItemFname = styled.td`
    display: flex;
    margin-top: 10px;
    width: 200px;
`;
const StyledTd = styled.td`
  width: 100px;  
  &:last-child {
    text-align: left;
  }
`;

const Fname = styled.div`
    padding: 5px;
    padding-left: 10px;
`;

const SectionItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const EmailItem = styled.div`
    padding-left: 10px;
    font-size: 13px;
    color: #ababab;
    padding-bottom: 7px;
`;

const Workflow =  styled.td`
    width: 220px;
`;

const Team =  styled.td`
    width: 180px;
`;

const Progress =  styled.td`
    width: 180px;
`;

const ProcessPercentage = styled.div<{color?: string}>`
    width: 100px;
    height: 10px;
    padding: 5px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: yellow;
    border-radius: 5px;
    background-color: ${props => props.color};
`;

const ProfileImage = styled.div``;

interface Props {
    id: string;
    position: string;
    firstName: string;
    status: BoardingStatus;
    percentage: number;
    lastName: string;
    team: string;
    workflow: string;
    createdAt: string;
    onClick: () => void;
}
const OnboardingTableIteam: React.FunctionComponent<Props> = ({ id, position, firstName, status, percentage, lastName, team, createdAt, workflow, onClick }) => {
    return <Iteam onClick={onClick}>
            <IdIteam></IdIteam>
            <ItemFname>

            <ProfileImage>
                <ProfileImageComponent firstName={ firstName } lastName = {lastName || ''} />
            </ProfileImage>
                <SectionItem><Fname>{ firstName } { lastName }</Fname> <EmailItem>{ position }</EmailItem></SectionItem></ItemFname>
            <StyledTd>{ status }</StyledTd>
            <Team> { team } </Team>
            <Workflow> { workflow } </Workflow>
            <Progress><ProcessPercentage color={ CalculateStatusColor(CalculatePercentageStatus(percentage)) }>{ percentage }%</ProcessPercentage></Progress>
            <StyledTd>{  createdAt }</StyledTd>
        </Iteam>
}

export default OnboardingTableIteam;
