import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getTeams = () => {
  return axios.get(API_URL + "team", { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const createTeam = (payload: any) => {
    return axios.post(API_URL + "team/create-team", payload, { headers: authHeader() });
};

export const getTeamById = (teamId: string) => {
  return axios.get(API_URL + "team/"+ teamId, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const removeEmployeeFromTeam = (teamId: string, employeeId: string) => {
    return axios.delete(API_URL + "team/" + teamId + "/" + employeeId, { headers: authHeader() });
};

export const updateTeam = (id:string, payload: any) => {
  return axios.put(API_URL + "team/" + id, payload, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};
