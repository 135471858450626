import axios from "axios";
import { API_URL } from "./auth.service";
import authHeader from "./authHeader.service";

export const getPositions = () => {
  return axios.get(API_URL + "settings/positions", { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const getPosition = (id:string) => {
  return axios.get(API_URL + "settings/positions/details/" + id, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const createPositions = (payload: any) => {
    return axios.post(API_URL + "settings/positions", payload, { headers: authHeader() });
};

export const updatePosition = (id:string, payload: any) => {
  return axios.put(API_URL + "settings/positions/" + id, payload, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });
};

export const deletePosition = (id:string) => {
  return axios.delete(API_URL + "settings/positions/" + id, { headers: authHeader() })
  .then((response)=> {    
    return response.data;
  });;
};