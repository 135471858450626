import * as React from 'react'
import IconTextComponent from '../components/IconText/IconText.component'
import LogoComponent from '../components/logo/Logo.component'
import './css/sidebar.css';
import SquareSolid from '../assets/svg/gray/up-right-from-square-solid.svg';
import CaretRight from '../assets/svg/gray/caret-right-solid.svg';
import { useNavigate } from 'react-router-dom';
import ProfileTitleComponent from '../components/ProfileTitle/ProfileTitle.component';
import { getTeams } from '../services/team.service';
import { getCurrentUser } from '../services/auth.service'

interface Props {
    children: React.ReactNode
}
const DashboardLayout: React.FunctionComponent<Props> = (props:Props) => {
    const navigate = useNavigate();

    const user = getCurrentUser();

    const handleLink = async (route: string) => {
        navigate(route);
    }

    const handleNavigate = async (url: string) => {
        navigate(url);
    }

    const [teams, setTeams] = React.useState<any[]>([]);

    React.useEffect(() => {
        async function getTeamsList() {
            const teams = await getTeams();
            console.log(teams);
            setTeams(teams);
        }
        getTeamsList();
    }, []);
    return <>
        <div className="wrapper">
            <div className="settings-wrapper"></div>
            <div className="sidebar-wrapper">
                <div className="sidebar-heading">
                    <LogoComponent />
                </div>
                <div className="list-group">
                    <ul className="list-items">
                        <li><IconTextComponent key="home-page" onClick={()=>handleLink('/dashboard')} symbol="H" text="Home" color="#406eb8" /></li>
                        <li><IconTextComponent key="employees" onClick={()=>handleLink('/employees')} symbol="E" text="Employees" color="#8cb031" /></li>
                        <li><IconTextComponent key="boarding" onClick={()=>handleLink('/boarding')} symbol="B" text="Boardings" color="#2a963c" /></li>
                        <li><IconTextComponent key="workflow" onClick={()=>handleLink('/workflow')} symbol="W" text="Workflows" color="#e6821e" /></li>
                        <li><IconTextComponent key="teams" onClick={()=>handleLink('/teams')} symbol="T" text="Teams" color="#5d4dab" /></li>
                        <li><IconTextComponent key="chapters" onClick={()=>handleLink('/chapters')} symbol="C" text="Chapters" color="#e01f50" /></li>
                        <li><IconTextComponent key="chapters" onClick={()=>handleLink('/catalog')} symbol="C" text="Assets" color="#543d94" /></li>
                        <li><IconTextComponent key="positions" onClick={()=>handleLink('/settings/positions')} symbol="P" text="Positions" color="#0fba81" /></li>
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="teams-list">
                    <div className="teams-title">
                        <span>Teams</span>
                        <span className="title-arrow"><img alt="" src={CaretRight} width="8" /></span>
                    </div>

                    <ul className="teams-items">
                        { teams ? teams.map((item)=> {
                            return <li className="teams-link" key={item.id} onClick = { ()=> handleNavigate(`/teams/${item.id}/view`)}>{item.name}</li>
                        }) : <li>Defatult Team</li> }
                    </ul>
                </div>
                <div className="divider"></div>
                <div className="list-docs">
                    <ul className="ul-docs">
                        <li>Docs <span className="outspan"><img alt="" src={SquareSolid} width="12" /></span></li>
                    </ul>
                </div>
                <div className="divider"></div>
            </div>
            <div className="page-content-wrapper">
                <div className="header">
                    <div className="username">
                        <ProfileTitleComponent symbols={`${user.firstName[0]}${user.lastName[0]}`} />
                    </div>
                </div>
                <div className="children-pad">
                    {props.children}
                </div>
            </div>
        </div>
    </>
}

export default DashboardLayout;
